<template lang="pug">
.search-filter-pseudo
  search-filter-inline-radio(
    :value="pseudoValue"
    hide-title
    mandatory
    :filter="yesNoAnyFilter"
    @input="setPseudoValue($event)"
  )
  mobile-dialog(v-if="pseudoValue === 'yes'" fit-content)
    template(#activator="{ active, open, close }")
      filter-box.mt-4(:value="humanValue" :active="active" @click="open")
    template(#default="{ close, contentKey }")
      edit-filter-base-dialog(
        :key="contentKey"
        #default="{ on }"
        :title="title || filter.label"
        @click:close="close"
        @click:confirm="close"
        @click:reset="clickReset(); close()"
        @update:returnValue="setValues($event); emitValues()"
      )
        search-filter-checkbox.px-4.pb-6(
          v-on="on"
          :value="values"
          hide-title
          :filter="filter"
        )
</template>

<script>
  import EditFilterBaseDialog from './EditFilterBaseDialog.vue';
  import SearchFilterCheckbox from './SearchFilterCheckbox.vue';
  import SearchFilterInlineRadio from './SearchFilterInlineRadio.vue';
  import MobileDialog from './MobileDialog.vue';
  import FilterBox from './FilterBox.vue';
  import { SearchFilter } from '@/services/assets/search-filter';

  export default {
    components: {
      MobileDialog,
      FilterBox,
      SearchFilterCheckbox,
      SearchFilterInlineRadio,
      EditFilterBaseDialog,
    },

    props: {
      filter: { type: Object, required: true },
      value: { type: Array, default: () => [] },
      title: { type: String, default: undefined },
    },

    data() {
      return {
        values: this.value,
        pseudoValue: 'any',
        yesNoAnyFilter: {
          options: [
            { key: 'yes', label: 'Да' },
            { key: 'no', label: 'Нет' },
            { key: 'any', label: 'Неважно' },
          ],
        },
      };
    },

    computed: {
      humanValue() {
        return SearchFilter.getHumanValue(this.filter, this.values);
      },
    },

    watch: {
      value(newValue) {
        this.setValues(newValue);
      },
    },

    mounted() {
      this.initialize();
    },

    methods: {
      initialize() {
        this.setValues(this.value);
      },

      setValues(values) {
        this.values = values;
        this.pseudoValue = this.calcPseudoValue(values);
      },

      setPseudoValue(value) {
        if (value === 'any' || value === 'no') {
          this.setValues([value]);
        } else if (value === 'yes') {
          this.setValues(this.filter.options.filter((o) => o.key !== 'any').map((o) => o.key));
        }

        // при изменении псевдо-переключалки подтверждаем выбор
        this.emitValues();
      },

      calcPseudoValue(values) {
        let pseudoValue = undefined;
        if (Array.isArray(values)) {
          if (values.length === 1 && values[0] === 'no') {
            pseudoValue = 'no';
          } else if ((values.length === 1 && values[0] === 'any') || values.length === 0) {
            pseudoValue = 'any';
          } else {
            pseudoValue = 'yes';
          }
        } else {
          pseudoValue = 'any';
        }
        return pseudoValue;
      },

      emitValues() {
        this.$emit('update:returnValue', this.values);
      },

      clickReset() {
        this.setValues(undefined);
        this.emitValues();
        this.$emit('reset');
      },
    },
  };
</script>
