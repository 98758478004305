<script>
  export default {
    functional: true,
    render(h, ctx) {
      const classes = ['info-panel', ctx.data.staticClass, ctx.data.class];
      return h('div', { ...ctx.data, class: classes }, ctx.children);
    },
  };
</script>

<style lang="scss">
  .info-panel {
    @include text-style('normal', 'light');
    border: 1px solid c('gray10', 'light');
    border-radius: 12px;
    padding: 24px 32px;
    color: c('gray60', 'light');
    text-align: center;
  }
</style>
