<template lang="pug">
v-dialog(
  v-model="isActive"
  content-class="filters-dialog"
  fullscreen
  hide-overlay
  no-click-animation
  persistent
  transition="dialog-bottom-transition"
)
  section.filters-dialog__header
    .filters-dialog__toolbar
      .filters-dialog__toolbar-reset(@click="reset") Сбросить
      .filters-dialog__toolbar-title Фильтры
      .filters-dialog__toolbar-close(@click="cancel")
        ui-icon(name="close")

  section.filters-dialog__content
    v-fade-transition(group leave-absolute)
      div(v-if="!assetQuery || !initialized" key="filters-dialog-skeleton")
        .filters-dialog-row
          .filters-dialog-row__content
            ui-skeleton(type="filterbox" width="100%")
        .filters-dialog-row
          .filters-dialog-row__title
            ui-skeleton(type="text" width=90)
          .filters-dialog-row__content
            ui-skeleton(type="filterbox")
        .filters-dialog-row
          .filters-dialog-row__title
            ui-skeleton(type="text" width=70)
          .filters-dialog-row__content
            ui-skeleton(type="filterbox")
        .filters-dialog-row
          .filters-dialog-row__title
            ui-skeleton(type="text" width=120)
          .filters-dialog-row__content
            ui-skeleton(type="filterbox")

      div(v-else key="filters-dialog-form")
        ui-btn-toggle.mb-6(
          v-if="initialized"
          :value="assetQuery.purpose"
          group
          mandatory
          width="100%"
          @input="setPurpose($event)"
        )
          ui-btn(value="buy") Купить
          ui-btn(value="rent") Снять

        .filters-dialog-row
          .filters-dialog-row__title Категория
          .filters-dialog-row__content
            mobile-dialog(height="90%")
              template(#activator="{ active, open }")
                filter-box(
                  :value="categoryTitle"
                  empty-value="Все категории"
                  :active="active"
                  @click="open"
                  :loading="fetchingCategories"
                )
              template(#default="{ close, contentKey }")
                category-dialog(
                  :key="contentKey"
                  v-if="categoriesTree"
                  :categories-tree="categoriesTree"
                  :category="currentCategory"
                  :skip-empty="true"
                  @click:close="close"
                  @update:category="currentCategory = $event; close()"
                  @update:kind="setKind($event)"
                )

        .filters-dialog-row
          .filters-dialog-row__title Регион
          .filters-dialog-row__content
            mobile-dialog(height="90%")
              template(#activator="{ active, open }")
                filter-box(
                  :value="humanValues.addresses"
                  empty-value="Все регионы и населенные пункты"
                  :active="active"
                  @click="open"
                )
              template(#default="{ close, contentKey }")
                region-dialog(
                  :key="contentKey"
                  :value="assetQuery.addresses"
                  @click:close="close"
                  @click:confirm="close"
                  @click:reset="close"
                  @update:returnValue="assetQuery.addresses = $event"
                )

        .filters-dialog-row(v-if="assetQuery.purpose === 'rent' && filtersMap.rent_price")
          .filters-dialog-row__title Стоимость аренды
          .filters-dialog-row__content
            mobile-dialog(fit-content)
              template(#activator="{ active, open, close }")
                filter-box(
                  :key="`rent_price_filterbox_${filtersMap.rent_price.options.min}_${filtersMap.rent_price.options.max}`"
                  :value="humanValues.rent_price"
                  :active="active"
                  :empty-value="getFullRangeValue('rent_price')"
                  @click="open"
                )
              template(#default="{ close, contentKey }")
                edit-filter-base-dialog(
                  :key="contentKey"
                  #default="{ on }"
                  :title="filtersMap.rent_price.label"
                  @click:close="close"
                  @click:confirm="close"
                  @click:reset="resetFilterValue('rent_price'); close()"
                  @update:returnValue="setFilterValue('rent_price', $event)"
                )
                  search-filter-range.px-4.py-6(
                    :key="`rent_price_filter_range_${filtersMap.rent_price.options.min}_${filtersMap.rent_price.options.max}`"
                    v-on="on"
                    :value="getFilterValue('rent_price')"
                    hide-title
                    :filter="filtersMap.rent_price"
                  )

        .filters-dialog-row(v-if="assetQuery.purpose === 'buy' && filtersMap.price")
          .filters-dialog-row__title Стоимость
          .filters-dialog-row__content
            mobile-dialog(fit-content)
              template(#activator="{ active, open, close }")
                filter-box(
                  :key="`price_filterbox_${filtersMap.price.options.min}_${filtersMap.price.options.max}`"
                  :value="humanValues.price"
                  :active="active"
                  :empty-value="getFullRangeValue('price')"
                  @click="open"
                )
              template(#default="{ close, contentKey }")
                edit-filter-base-dialog(
                  :key="contentKey"
                  #default="{ on }"
                  :title="filtersMap.price.label"
                  @click:close="close"
                  @click:confirm="close"
                  @click:reset="resetFilterValue('price'); close()"
                  @update:returnValue="setFilterValue('price', $event)"
                )
                  search-filter-range.px-4.py-6(
                    :key="`price_filter_range_${filtersMap.price.options.min}_${filtersMap.price.options.max}`"
                    v-on="on"
                    :value="getFilterValue('price')"
                    hide-title
                    :filter="filtersMap.price"
                  )

        .filters-dialog-row(v-if="assetQuery.purpose === 'buy' && filtersMap.sale_type")
          .filters-dialog-row__title Способ покупки
          .filters-dialog-row__content
            mobile-dialog(fit-content)
              template(#activator="{ active, open, close }")
                filter-box(:value="humanValues.sale_type" :active="active" @click="open")
              template(#default="{ open, close, contentKey }")
                edit-filter-base-dialog(
                  :key="contentKey"
                  #default="{ on }"
                  :title="filtersMap.sale_type.label"
                  @click:close="close"
                  @click:confirm="close"
                  @click:reset="resetFilterValue('sale_type'); close()"
                  @update:returnValue="setFilterValue('sale_type', $event)"
                )
                  search-filter-checkbox.px-4.py-6(
                    v-on="on"
                    :value="getFilterValue('sale_type')"
                    hide-title
                    :filter="filtersMap.sale_type"
                  )

        .filters-dialog-extra-filters(v-if="assetQuery.purpose === 'buy'")
          div(v-if="!(showExtraFilters || hasExtraValues)" @click="showExtraFilters = true")
            .t-norma.c-primary Еще фильтры
            .t-small.c-gray70 Продавец, залог, банкротство, испол. производство
          .filters-dialog-extra-filters__content(v-else)
            .filters-dialog-row(v-if="filtersMap.seller_type && filtersMap.sellers")
              .filters-dialog-row__title Продавец
              .filters-dialog-row__content
                mobile-dialog(height="90%")
                  template(#activator="{ active, open, close }")
                    filter-box(
                      :value="humanValues.sellers || humanValues.seller_type"
                      :active="active"
                      @click="open"
                    )
                  template(#default="{ close, contentKey }")
                    search-sellers-dialog(
                      :key="contentKey"
                      :sellerTypeFilter="filtersMap.seller_type"
                      :sellerTypeValue="getFilterValue('seller_type')"
                      :sellersFilter="filtersMap.sellers"
                      :sellersValue="getFilterValue('sellers')"
                      @click:close="close"
                      @click:confirm="close"
                      @click:reset="close"
                      @update:sellerTypeValue="setFilterValue('seller_type', $event)"
                      @update:sellersValue="setFilterValue('sellers', $event)"
                    )

            .filters-dialog-row(v-if="filtersMap.pledge_status")
              .filters-dialog-row__title В залоге
              .filters-dialog-row__content
                search-filter-inline-radio(
                  :value="getFilterValue('pledge_status')"
                  hide-title
                  mandatory
                  :filter="filtersMap.pledge_status"
                  @input="setFilterValue('pledge_status', $event)"
                )

            .filters-dialog-row(v-if="filtersMap.bankruptcy")
              .filters-dialog-row__title Банкротство
              .filters-dialog-row__content
                search-filter-pseudo(
                  :value="getFilterValue('bankruptcy')"
                  key="bankruptcy"
                  :filter="filtersMap.bankruptcy"
                  @click:reset="resetFilterValue('bankruptcy')"
                  @update:returnValue="setFilterValue('bankruptcy', $event)"
                )

            .filters-dialog-row(v-if="filtersMap.execution_procedure")
              .filters-dialog-row__title Исполнительное производство
              .filters-dialog-row__content
                search-filter-pseudo(
                  :value="getFilterValue('execution_procedure')"
                  key="execution_procedure"
                  title="Испол. производство"
                  :filter="filtersMap.execution_procedure"
                  @click:reset="resetFilterValue('execution_procedure')"
                  @update:returnValue="setFilterValue('execution_procedure', $event)"
                )

        .filters-dialog-category-filters(v-if="initialized && !assetQuery.kind")
          .t-h3-mobile.mb-4 Фильтры категории
          .filters-dialog__category-filters-info Выберите категорию, чтобы увидеть дополнительные фильтры

        .filters-dialog-category-filters(v-if="categoryFilters && categoryFilters.length")
          .t-h3-mobile.mb-4 Фильтры категории
          .filters-dialog-row(v-for="f in categoryFilters")
            .filters-dialog-row__title {{ f.label }}
            .filters-dialog-row__content
              template(
                v-if="f.type === 'inline-checkbox' || f.type === 'inline-radio' || f.type === 'inline-checkbox-chip'"
              )
                component(
                  :key="f.type + '-' + f.name"
                  :value="getFilterValue(f.name)"
                  hide-title
                  mandatory
                  :filter="f"
                  :is="'search-filter-' + f.type"
                  @input="setFilterValue(f.name, $event)"
                )
              template(v-else)
                mobile-dialog(:fit-content="!f.hasTooManyOptions()")
                  template(#activator="{ active, open, close }")
                    filter-box(
                      :key="'search-filter-box-' + f.type + '-' + f.name"
                      :value="humanValues[f.name]"
                      :active="active"
                      :empty-value="f.type === 'range' ? f.getRangeHumanValue() : undefined"
                      @click="open"
                    )
                  template(#default="{ close, contentKey }")
                    edit-filter-base-dialog(
                      :key="contentKey"
                      #default="{ on }"
                      :title="filtersMap[f.name].label"
                      @click:close="close"
                      @click:confirm="close"
                      @click:reset="resetFilterValue(f.name); close()"
                      @update:returnValue="setFilterValue(f.name, $event)"
                    )
                      component.px-4.py-6(
                        :key="`${f.type}-${f.name}-${contentKey}`"
                        v-on="on"
                        :value="getFilterValue(f.name)"
                        hide-title
                        no-empty-option
                        :filter="f"
                        :is="'search-filter-' + f.type"
                      )

  footer.filters-dialog__footer
    ui-btn(
      v-if="!initialized || fetchingCount"
      large
      primary
      width="100%"
      @click="confirm"
    )
      ui-loader(small inline)
    ui-btn(
      v-else
      large
      primary
      width="100%"
      @click="confirm"
    ) {{ showAssetsText }}
</template>
<script>
  import {
    UiChip,
    UiChipGroup,
    UiBtnToggle,
    UiBtn,
    UiIcon,
    UiLoader,
    UiExpansionPanel,
    UiSkeleton,
  } from '@/uikit';

  import SearchFilterRadio from '@/components/search/SearchFilterRadio.vue';
  import SearchFilterRange from '@/components/search/SearchFilterRange.vue';
  import SearchFilterCheckbox from '@/components/search/SearchFilterCheckbox.vue';
  import SearchFilterInlineCheckbox from '@/components/search/SearchFilterInlineCheckbox.vue';
  import SearchFilterInlineCheckboxChip from '@/components/search/SearchFilterInlineCheckboxChip.vue';

  import SearchSellersDialog from '@/components/search/SearchSellersDialog.vue';
  import SearchFilterInlineRadio from '@/components/search/SearchFilterInlineRadio.vue';

  import SearchFilterPseudo from './SearchFilterPseudo.vue';
  import FilterBox from './FilterBox.vue';
  import EditFilterBaseDialog from '@/components/search/EditFilterBaseDialog.vue';
  import MobileDialog from '@/components/search/MobileDialog.vue';
  import CategoryDialog from '@/components/search/CategoryDialog.vue';
  import RegionDialog from '@/components/search/RegionDialog.vue';

  import { SearchFilter } from '@/services/assets/search-filter.js';
  import { AssetQuery } from '@/services/assets/asset-query.js';
  import { CategoriesTree } from '@/services/assets/categories-tree.js';
  import { service as API } from '@/services/assets';

  import { formatAssetsCount } from '@/uikit/filters/pluralize.js';
  import { handleError } from '@/libs/handle-error';

  import { HIDDEN_FILTERS, MAIN_FILTERS } from '@/store/catalog';

  import { createLogger } from '@/uikit/util/logger.js';
  const logger = createLogger('FiltersDialog', 'orange');

  export default {
    name: 'filters-dialog',
    components: {
      UiLoader,
      UiIcon,
      UiChip,
      UiChipGroup,
      UiBtnToggle,
      UiBtn,
      UiExpansionPanel,
      UiSkeleton,
      MobileDialog,
      EditFilterBaseDialog,
      CategoryDialog,
      RegionDialog,
      SearchFilterRange,
      SearchFilterRadio,
      SearchFilterInlineRadio,
      SearchFilterCheckbox,
      SearchFilterInlineCheckbox,
      SearchFilterInlineCheckboxChip,
      SearchSellersDialog,
      SearchFilterPseudo,
      FilterBox,
    },

    props: {
      value: { type: Object, default: () => {} },
      categoriesTreeObject: { type: Object, default: undefined },
    },

    data() {
      return {
        isActive: false,
        resolve: null,
        reject: null,
        initialized: false,
        fetchingCategories: false,
        fetchingFilters: false,
        currentCategory: undefined,
        categoriesTree: this.categoriesTreeObject,
        assetQuery: undefined,
        assetsTotal: undefined,
        fetchingCount: undefined,
        showExtraFilters: false,
      };
    },

    computed: {
      filtersMap() {
        if (!this.initialized) return {};

        return this.assetQuery.getFilters().reduce((map, f) => {
          map[f.name] = f;
          return map;
        }, {});
      },

      filters() {
        if (!this.initialized) return [];

        return this.assetQuery.getFilters();
      },

      categoryFilters() {
        return this.filters.filter((f) => ![...MAIN_FILTERS, ...HIDDEN_FILTERS].includes(f.name));
      },

      showAssetsText() {
        return `Показать ${formatAssetsCount(this.assetsTotal)}`;
      },

      categoryTitle() {
        return this.currentCategory ? this.currentCategory.title : '';
      },

      humanValues() {
        if (!this.initialized) return {};

        const humanValues = this.filters.reduce((map, f) => {
          map[f.name] = f.getHumanValue();
          return map;
        }, {});

        humanValues.addresses = SearchFilter.getHumanValue(
          { type: 'suggestion' },
          this.assetQuery.addresses
        );
        return humanValues;
      },

      searchParams() {
        if (!this.initialized) return undefined;
        return this.assetQuery.toJSON();
      },

      hasExtraValues() {
        if (!this.initialized) return false;

        return ['pledge_status', 'bankruptcy', 'execution_procedure'].reduce(
          (hasValue, filterName) => {
            const filter = this.filtersMap[filterName];
            return hasValue || (filter && !filter.isEmpty());
          },
          false
        );
      },
    },

    watch: {
      searchParams(newValue) {
        if (!newValue || !this.initialized) return;
        this.fetchAssetsCount();
      },

      hasExtraValues(newValue) {
        if (newValue === true) {
          this.showExtraFilters = true;
        }
      },
    },

    methods: {
      open(searchData, filtersData) {
        this.assetQuery = undefined;
        this.showExtraFilters = false;
        this.initialized = false;
        this.initialize(searchData, filtersData);
        this.isActive = true;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },

      async initialize(searchData, filtersData) {
        logger.log('Инициализация диалога', searchData, filtersData);
        try {
          this.initialized = false;
          const assetQuery = new AssetQuery(searchData);
          logger.log('Начальный assetQuery:', JSON.stringify(assetQuery));
          // получаем фильтры, если не дали ничего
          let fd = filtersData;
          if (!fd || (Array.isArray(fd) && fd.length === 0)) {
            fd = await this.fetchFilters(assetQuery.purpose, assetQuery.kind);
            logger.log('Тянем доступные фильтры...', fd);
          }
          assetQuery.buildFilters(fd);
          assetQuery.assignFromCriterias(searchData.filters);
          logger.log('Сформированный assetQuery:', JSON.stringify(assetQuery));

          // категории подгружаем если еще не грузили или изменился purpose
          if (
            !this.categoriesTree ||
            !this.assetQuery ||
            (this.assetQuery && this.assetQuery.purpose !== assetQuery.purpose)
          ) {
            this.categoriesTree = await this.fetchCategories(assetQuery.purpose);
            this.currentCategory = this.categoriesTree.find(assetQuery.kind, (c) => c.total > 0);
          }
          this.assetQuery = assetQuery;
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.initialized = true;
        }
      },

      setPurpose(purpose) {
        this.initialize({ purpose: purpose });
      },

      setKind(kind) {
        // ничего не меняем
        if (this.assetQuery.kind === kind) return;

        // берем что есть
        const assetQuery = new AssetQuery(this.assetQuery.toJSON());
        // ставим новую категорию
        assetQuery.setKind(kind);
        // скидываем в начало
        assetQuery.setPage(1);

        const searchData = assetQuery.toJSON();

        // мерджим допустимые значения при смене категории
        // мерждим только для "покупки"
        // ибо в "снять" остается только "цена аренды"
        if (assetQuery.purpose === 'buy') {
          searchData.filters = this.assetQuery.extractCriterias([
            'sale_type',
            'sellers',
            'price',
            'seller_type',
            'pledge_status',
            'bankruptcy',
            'execution_procedure',
          ]);
        } else {
          searchData.filters = this.assetQuery.extractCriterias(['rent_price']);
        }

        logger.log(`Меняем категорию: ${this.assetQuery.kind} -> ${kind}`, searchData);
        this.initialize(searchData);
      },

      confirm() {
        this.isActive = false;
        this.resolve(this.assetQuery);
      },

      cancel() {
        this.isActive = false;
        this.resolve(false);
      },

      async fetchAssetsCount() {
        try {
          logger.log('Сколько активов доступно:');
          this.fetchingCount = true;
          this.assetsTotal = await API.getAssetsCount(
            this.assetQuery.toJSON(),
            this.assetQuery.purpose
          );
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.fetchingCount = false;
        }
      },

      async fetchCategories(purpose) {
        try {
          this.fetchingCategories = true;
          const categories = await API.getCategories(purpose);
          return new CategoriesTree(categories);
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.fetchingCategories = false;
        }
      },

      async fetchFilters(purpose, kind) {
        try {
          this.fetchingFilters = true;
          const filtersData = await API.getFilters(kind, purpose);
          return filtersData;
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.fetchingFilters = false;
        }
      },

      reset() {
        this.assetQuery.reset();
      },

      setFilterValue(filterName, value) {
        const sf = this.filtersMap[filterName];
        logger.log('setFilterValue', filterName, value);
        if (sf) sf.setValue(value);
      },

      getFilterValue(filterName) {
        const sf = this.filtersMap[filterName];
        if (sf) {
          const value = sf.getValue();
          logger.log('getFilterValue', filterName, value);
          return value;
        } else {
          logger.error('Фильтр не найден', filterName);
        }
      },

      resetFilterValue(filterName) {
        const sf = this.filters.find((f) => f.name === filterName);
        sf.reset();
      },

      getFullRangeValue(filterName) {
        const filter = this.filtersMap[filterName];
        return filter.getRangeHumanValue();
      },
    },
  };
</script>

<style lang="scss">
  .filters-dialog {
    background-color: c('white', 'light');
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  .filters-dialog__header {
    width: 100%;
  }

  .filters-dialog__toolbar {
    position: relative;
    display: flex;
    width: 100%;
    height: 48px;
    align-items: center;
    box-shadow: shadow('xsmall', 'light');
  }

  .filters-dialog__toolbar-reset {
    @include text-style('button-middle', 'light');
    position: absolute;
    top: 0px;
    left: 0;
    width: 102px;
    height: 48px;
    padding: 16px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: c('primary60', 'light');
  }

  .filters-dialog__toolbar-title {
    @include text-style('h3-mobile', 'light');
    text-align: center;
    flex-grow: 1;
    flex-shrink: 0;
    color: c('gray100', 'light');
  }

  .filters-dialog__toolbar-close {
    position: absolute;
    top: 0px;
    right: 0;
    width: 48px;
    height: 48px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: c('gray70', 'light');
  }

  .filters-dialog__content {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
    min-height: 0;
    max-height: 100%;
    height: 100%;
    padding: 24px 16px;
  }

  .filters-dialog__footer {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 12px 16px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.06);

    & .theme--light.btn-primary.v-btn:not(.ui-state--disabled):hover {
      background-color: c('primary60', 'light') !important;
    }
  }

  .filters-dialog__main {
    background-color: c('light', 'light');
  }

  .filters-dialog__main-filters {
    position: relative;
    padding: 16px;
    min-height: 240px;
    background-color: c('white', 'light');

    &::after {
      content: '';
      display: block;
      height: 8px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: shadow('xsmall', 'light');
    }
  }

  .filters-dialog-extra-filters {
    margin-bottom: 24px;
  }

  .filters-dialog-category-filters {
    padding: 24px 16px 0 16px;
    margin: 24px -16px 0 -16px;
    border-top: 1px solid c('gray10', 'light');
  }

  .filters-dialog-extra-filters__content {
  }

  .filters-dialog-row {
    margin-bottom: 24px;

    .filters-dialog-row__title {
      @include text-style('caption-middle-bold', 'light');
      color: c('gray100', 'light');
      margin-bottom: 6px;
    }

    .filters-dialog-row__content {
    }
  }

  .filters-dialog__category-filters-info {
    @include text-style('normal', 'light');
    border: 1px solid c('gray10', 'light');
    border-radius: 12px;
    padding: 24px 32px;
    color: c('gray60', 'light');
    text-align: center;
  }
</style>
