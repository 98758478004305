<template lang="pug">
.mobile-dialog-wrapper(:class="{ '-bottom-sheet': bottomSheet }")
  slot(
    name="activator"
    :active="isActive"
    :close="close"
    :open="open"
  )
  component(
    v-model="isActive"
    click:outside="close"
    :content-class="contentClasses"
    :is="dialogComponent"
  )
    slot(
      v-if="isVisible"
      :active="isActive"
      :close="close"
      :contentKey="contentKey"
      :open="open"
    )
</template>

<script>
  export default {
    props: {
      value: { type: Boolean, default: false },
      closeOnContentClick: { type: Boolean, default: false },
      bottomSheet: { type: Boolean, default: true },
      fitContent: { type: Boolean, default: false },
    },

    data() {
      return {
        isVisible: this.value,
        isActive: this.value,
        contentKey: undefined,
      };
    },

    computed: {
      contentClasses() {
        let classes = ['mobile-dialog'];
        if (this.bottomSheet) classes.push('-bottom-sheet');
        if (this.fitContent) classes.push('-fit-content');
        return classes.join(' ');
      },

      dialogComponent() {
        return this.bottomSheet ? 'v-bottom-sheet' : 'v-dialog';
      },
    },

    watch: {
      value(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.isActive = newValue;
        }
      },

      isActive(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit('input', newValue);
        }
      },
    },

    methods: {
      open() {
        this.isActive = true;
        this.isVisible = true;
        this.contentKey = this.genContentKey();
        this.$emit('open');
      },

      close() {
        this.isActive = false;
        setTimeout(() => {
          this.isVisible = false;
        }, 300);
        this.$emit('close');
      },

      onClick() {
        if (this.closeOnContentClick) {
          this.$nextTick(() => {
            this.close();
          });
        }
      },

      genContentKey() {
        return Math.random().toString(36).substr(2, 9);
      },
    },
  };
</script>

<style lang="scss">
  .mobile-dialog {
    background-color: unset;

    &.-bottom-sheet {
      height: 90%;
    }

    &.-fit-content {
      max-height: 90%;
      height: min-content;
    }
  }
</style>
