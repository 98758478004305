<template lang="pug">
.search-filter-range
  slot(v-if="!hideTitle" name="title" :title="filter.label")
    h6.text--h6.mb-2 {{ filter.label }}
  range-slider(
    :key="`range-slider_${filter.name}_${filter.type}_${filter.options.min}_${filter.options.max}`"
    v-model="valueModel"
    :error.sync="error"
    :max="filter.options.max"
    :min="filter.options.min"
    :symbol="filter.measure"
  )
</template>

<script>
  import RangeSlider from '@/uikit/components/RangeSlider.vue';

  export default {
    name: 'search-filter-range',
    components: { RangeSlider },

    props: {
      value: { type: Object, default: undefined },
      filter: { type: Object, required: true },
      hideTitle: { type: Boolean, default: false },
    },

    data() {
      return {
        error: false,
      };
    },

    computed: {
      valueModel: {
        set(val) {
          if (!this.error) {
            console.log('SearchFilterRange: emit value', val);
            this.$emit('input', val);
          }
        },
        get() {
          console.log('SearchFilterRange: get value', this.value);
          return this.value;
        },
      },
    },
  };
</script>
