<template lang="pug">
edit-filter-base-dialog.region-dialog(
  v-bind="$attrs"
  title="Регион"
  @click:close="clickClose"
  @click:confirm="clickConfirm"
  @click:reset="clickReset"
)
  template(#toolbar)
    .px-4
      ui-search-text-field(
        ref="query_input"
        v-model="query"
        placeholder="Найти по названию"
        :clearable="hasQuery"
        @clear="query = undefined"
        :loading="loading"
      )

    .location-addresses(v-if="addresses && addresses.length")
      ui-chip.location-addresses__item(
        v-for="(address, index) in addresses"
        :key="'location-address' + index"
        color="primary"
        active=false
        close
        dark
        @click:close="removeAddress(address)"
      ) {{ address }}

  template(#default)
    .region-dialog__content
      template(v-if="suggestions && suggestions.length > 0")
        v-list.region-dialog-list
          v-list-item.region-dialog-item(
            v-for="(location, index) in suggestions"
            :key="index"
            two-line
            @click="addAddress(location.value)"
          )
            v-list-item-content
              v-list-item-title.region-dialog-item__title {{ location.value }}
              v-list-item-subtitle.region-dialog-item__desc {{ location.desc }}
      info-panel.mx-4.mt-6(v-if="hasQuery && !loading && suggestions && !suggestions.length")
        | По вашему запросу не найдено совпадений
</template>

<script>
  import { UiLoader, UiIcon, UiChip, UiSearchTextField } from '@/uikit';
  import EditFilterBaseDialog from '@/components/search/EditFilterBaseDialog.vue';
  import InfoPanel from '@/components/search/InfoPanel.vue';
  import debounce from 'underscore/modules/debounce';
  import { service as API } from '@/services/assets/assets-service';
  import { handleError } from '@/libs/handle-error';
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('SearchCategoryDropdown', 'orange');

  const LOCATION_HINTS = [
    { value: 'Москва', desc: 'Российская Федерация' },
    { value: 'Санкт-Петербург', desc: 'Российская Федерация' },
    { value: 'г Екатеринбург', desc: 'Российская Федерация, Свердловская область' },
    { value: 'г Самара', desc: 'Российская Федерация, Самарская область' },
    { value: 'г Казань', desc: 'Российская Федерация, Татарстан' },
    { value: 'г Омск', desc: 'Российская Федерация, Омская область' },
    { value: 'г Новосибирск', desc: 'Российская Федерация, Новосибирская область' },
    { value: 'г Кострома', desc: 'Российская Федерация, Костромская область' },
    { value: 'г Уфа', desc: 'Российская Федерация, Башкортостан' },
    { value: 'г Набережные Челны', desc: 'Российская Федерация, Татарстан' },
    { value: 'г Раменское', desc: 'Российская Федерация, Московская область' },
    { value: 'г Пермь', desc: 'Российская Федерация, Пермский край' },
    { value: 'г Красноярск', desc: 'Российская Федерация, Красноярский край' },
    { value: 'г Краснодар', desc: 'Российская Федерация, Краснодарский край' },
    { value: 'г Ставрополь', desc: 'Российская Федерация, Ставропольский край' },
    { value: 'г Оренбург', desc: 'Российская Федерация, Оренбургская область' },
    { value: 'г Петухово', desc: 'Российская Федерация, Курганская область' },
    { value: 'г Киров', desc: 'Российская Федерация, Курганская область' },
  ];

  export default {
    components: {
      EditFilterBaseDialog,
      InfoPanel,
      UiChip,
      UiIcon,
      UiLoader,
      UiSearchTextField,
    },

    inheritAttrs: false,

    props: {
      value: { type: Array, default: () => [] },
    },

    data() {
      return {
        addresses: this.value,
        query: '',
        loading: false,
        suggestions: LOCATION_HINTS,
      };
    },

    computed: {
      hasQuery() {
        return this.query && this.query.length > 0;
      },
    },

    watch: {
      query(value) {
        if (value) {
          this.debouncedSearch();
        } else {
          this.initSuggestions();
        }
      },

      value(newValue) {
        this.addresses = newValue;
      },

      addresses(newValue) {
        if (newValue && newValue.length == 0 && !this.hasQuery) {
          this.initSuggestions();
        }
      },
    },

    created() {
      this.debouncedSearch = debounce(this.fetchLocations, 400);
    },

    mounted() {
      this.initialize();
    },

    methods: {
      initialize() {
        logger.log('Инициализация', `[${this.addresses}]`);
        this.initSuggestions();
      },

      addAddress(item) {
        const addresses = this.addresses || [];
        if (addresses.includes(item)) return;
        this.addresses = [...addresses, item];
      },

      removeAddress(address) {
        this.addresses = this.addresses.filter((v) => v != address);
      },

      async fetchLocations() {
        if (this.loading || !this.hasQuery) return;

        this.loading = true;
        try {
          const suggestions = await API.getLocations(this.query);
          this.suggestions = suggestions || [];
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.loading = false;
        }
      },

      initSuggestions() {
        this.suggestions = LOCATION_HINTS;
      },

      clickClose() {
        this.$emit('click:close');
      },

      clickConfirm() {
        this.$emit('update:returnValue', this.addresses);
        this.$emit('click:confirm');
      },

      clickReset() {
        this.addresses = [];
        this.$emit('update:returnValue', this.addresses);
        this.$emit('click:reset');
      },
    },
  };
</script>

<style lang="scss">
  .region-dialog {
    .region-dialog-list {
    }

    .location-addresses {
      display: flex;
      flex-wrap: wrap;
      padding: 16px 16px 0;
    }

    .location-addresses__item {
      margin-right: 8px;
      margin-bottom: 8px;
    }

    .region-dialog-item {
      width: 100%;
      max-width: 100%;

      .region-dialog-item__title {
        @include text-style('caption-normal', 'light');
        color: c('gray80', 'light');
      }

      .region-dialog-item__desc {
        @include text-style('caption-small', 'light');
        margin-top: 2px;
        color: c('gray60', 'light');
      }
    }
  }
</style>
