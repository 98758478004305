<template lang="pug">
.search-filter-radio
  slot(v-if="!hideTitle" name="title" :title="filter.label")
    h6.text--h6 {{ filter.label }}

  //- v-list
  v-radio-group.search-filter-radio-list(v-model="filterValue")
    v-radio.search-filter-radio-list__item(
      v-for="option in options"
      :key="'filter-radio-' + filter.name + '-' + option.key"
      :value="option.key"
      hide-details
    )
      template(#label)
        .t-caption-normal {{ option.label }}
</template>

<script>
  export default {
    name: 'search-filter-radio',
    props: {
      filter: { type: Object, required: true },
      hideTitle: { type: Boolean, default: false },
      value: { type: String, default: undefined },
      noEmptyOption: { type: Boolean, default: false },
    },

    computed: {
      options() {
        if (!this.noEmptyOption) return this.filter.options;
        const options = this.filter.options.filter((opt) => opt.key !== '' && opt.key !== 'any');
        return options;
      },

      filterValue: {
        set(value) {
          this.$emit('input', value);
        },
        get() {
          return this.value;
        },
      },
    },
  };
</script>

<style lang="scss">
  .search-filter-radio-list {
    margin: 0;
    padding: 0 !important;

    .search-filter-radio-list__item {
      margin: 0 !important;
      padding: 9px 0;
      border-bottom: 1px solid c('gray10', 'light');
      color: c('gray100', 'light') !important;
    }
  }
</style>
