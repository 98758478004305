<script>
  export default {
    name: 'event-listener',

    data() {
      return {};
    },

    render() {
      return this.$scopedSlots.default({
        on: this.$listeners,
      });
    },
  };
</script>
