<template lang="pug">
.search-filter-inline-checkbox
  slot(v-if="!hideTitle" name="title" :title="filter.label")
    h6.text--h6 {{ filter.label }}

  .button-group.button-group--full
    .button-group__button.checkbox-button.checkbox-button--small(
      v-for="(option, index) in options"
      :key="filter.name + '-' + index"
    )
      input.checkbox-button__input(
        v-model="values"
        type="checkbox"
        :value="option.key"
        :id="'filter-' + filter.name + '-' + option.key"
        :name="'filter-' + filter.name"
        @change="valueChanged"
      )
      label.checkbox-button__label(:for="'filter-' + filter.name + '-' + option.key") {{ option.label | capitalize }}
</template>

<script>
  export default {
    name: 'search-filter-inline-checkbox',
    props: {
      value: { type: Array, default: () => [] },
      filter: { type: Object, required: true },
      hideTitle: { type: Boolean, default: false },
      small: { type: Boolean, default: false },
      noEmptyOption: { type: Boolean, default: false },
    },

    data() {
      return {
        values: this.value,
      };
    },

    computed: {
      options() {
        if (!this.noEmptyOption) return this.filter.options;
        const options = this.filter.options.filter((opt) => opt.key !== '' && opt.key !== 'any');
        return options;
      },
    },

    watch: {
      value(newValue) {
        this.values = newValue;
      },
    },

    methods: {
      valueChanged(event) {
        const value = event.target.value;

        // кликнули на "Неважно" (пустое значение)
        if (value === 'any') {
          // обнуляем все, кроме "Неважно"
          this.values = ['any'];
        } else {
          let values = [...this.values];

          values = values.filter((v) => v !== 'any');
          this.values = values;
        }
        this.$emit('input', this.values);
      },
    },
  };
</script>
