<template lang="pug">
.search-filter-inline-checkbox-chip
  slot(v-if="!hideTitle" name="title" :title="filter.label")
    h6.text--h6 {{ filter.label }}

  ui-chip-group(
    :value="values"
    multiple
    :column="column"
    :mandatory="mandatory"
    :show-arrows="showArrows"
    @change="valueChanged"
  )
    ui-chip(
      v-for="(option, index) in options"
      :key="filter.name + '-' + index"
      :value="option.key"
    ) {{ option.label | capitalize }}
</template>

<script>
  import { UiChip, UiChipGroup } from '@/uikit';

  export default {
    name: 'search-filter-inline-checkbox-chip',
    components: { UiChip, UiChipGroup },
    props: {
      value: { type: Array, default: () => ['any'] },
      filter: { type: Object, required: true },
      hideTitle: { type: Boolean, default: false },
      small: { type: Boolean, default: false },
      noEmptyOption: { type: Boolean, default: false },
      showArrows: { type: Boolean, default: true },
      mandatory: { type: Boolean, default: false },
      column: { type: Boolean, default: false },
    },

    data() {
      return {
        values: this.value,
        prevValue: undefined,
      };
    },

    computed: {
      options() {
        if (!this.noEmptyOption) return this.filter.options;
        const options = this.filter.options.filter((opt) => opt.key !== '' && opt.key !== 'any');
        return options;
      },
    },

    watch: {
      value(newValue) {
        this.values = newValue;
      },
    },

    created() {
      if (
        this.preselectIndex !== undefined &&
        this.filter?.options &&
        this.filter.options[this.preselectIndex]
      ) {
        this.values = this.multiple
          ? [this.filter.options[this.preselectIndex].key]
          : this.filter.options[this.preselectIndex].key;
        this.$emit('input', this.values);
      }
    },

    methods: {
      valueChanged(newValue) {
        let values = [];
        this.prevValue = [...this.values];
        const emptyChecked = newValue.includes('any');
        const wasEmpty = this.prevValue.length === 1 && this.prevValue.includes('any');

        if (!this.noEmptyOption) {
          if (!emptyChecked && !wasEmpty) {
            values = newValue;
          } else if (emptyChecked && !wasEmpty) {
            values = ['any'];
          } else if (newValue.length > 1 && emptyChecked && wasEmpty) {
            values = newValue.filter((v) => v !== 'any');
          }
        } else {
          values = newValue;
        }
        this.values = values;
        this.$emit('input', this.values);
      },
    },
  };
</script>

<style lang="scss">
  .search-filter-inline-checkbox-chip {
    .ui-chip-group.v-chip-group .v-slide-group__content {
      // padding-top: 4px !important;
      // padding-bottom: 4px !important;
    }
    .ui-chip-group.v-chip-group .v-chip {
      margin-top: 4px !important;
      // margin-bottom: 4px !important;
    }
  }
</style>
