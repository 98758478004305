<template lang="pug">
.range-slider(:class="{ 'range-slider--slider-on-bottom': sliderOnBottom }")
  .range-slider__slider
    v-range-slider(
      :value="sliderValue"
      hide-details
      thumb-color="#53AD59"
      track-color="#D5EED6"
      track-fill-color="#75BD7A"
      :max="max"
      :min="min"
      @input="setSliderValue"
    )
  .range-slider__inputs
    v-text-field.range-slider__input.range-slider__input--min.mt-0.pt-0(
      type="number"
      :value="inputMinValue"
      hide-details
      :error="minError"
      @blur="fixMinValue"
      @change="newMinValue = $event"
    )
      template(#prepend-inner)
        span.range-slider__tooltip--text от
      template(#append)
        span.text--nowrap(v-html="symbol")

    .range-slider__divider

    v-text-field.range-slider__input.range-slider__input--max.mt-0.pt-0(
      type="number"
      :value="inputMaxValue"
      hide-details
      :error="maxError"
      @blur="fixMaxValue"
      @change="newMaxValue = $event"
    )
      template(#prepend-inner)
        span.range-slider__tooltip--text до
      template(#append)
        span.text--nowrap(v-html="symbol")
</template>

<script>
  import debounce from 'underscore/modules/debounce';

  import { createLogger } from '@/uikit/util/logger.js';
  const logger = createLogger('RangeSlider', 'orange');

  export default {
    name: 'range-slider',

    props: {
      symbol: { type: String, default: undefined },
      min: { type: Number, default: undefined },
      max: { type: Number, default: undefined },
      value: { type: Object, default: undefined },
      sliderOnBottom: { type: Boolean, default: true },
    },

    data() {
      return {
        minError: false,
        maxError: false,
        inputMinValue: undefined,
        inputMaxValue: undefined,
        newMinValue: undefined,
        newMaxValue: undefined,
        validatedMinValue: undefined,
        validatedMaxValue: undefined,
        process: (dotsPos) => [
          [0, dotsPos[0], { backgroundColor: '#75BD7A', opacity: 0.4, height: '1px' }],
          [dotsPos[0], dotsPos[1], { backgroundColor: '#75BD7A', opacity: 1, height: '2px' }],
          [dotsPos[1], 100, { backgroundColor: '#75BD7A', opacity: 0.4, height: '1px' }],
        ],
      };
    },

    computed: {
      sliderValue() {
        const min = Number(this.inputMinValue);
        const max = Number(this.inputMaxValue);
        return [min, max];
      },
    },

    watch: {
      value(val) {
        this.setValue(val);
      },

      minError(val) {
        this.$emit('update:error', val || this.maxError);
      },

      maxError(val) {
        this.$emit('update:error', val || this.minError);
      },
    },

    created() {
      this.setValue(this.value);
      this.debouncedEmitInput = debounce(this.emitValue, 400);
    },

    methods: {
      setSliderValue(val) {
        logger.log('setSliderValue', val[0], val[1]);
        this.inputMinValue = val[0];
        this.inputMaxValue = val[1];
        this.debouncedEmitInput();
      },

      fixMinValue() {
        logger.log('fixMinValue', this.newMinValue);
        this.minError = false;
        if (this.newMinValue === undefined) return;

        let newValue = this.newMinValue;
        newValue = `${newValue}`;
        newValue = newValue.replace(/[^\d]/g, '').trim();

        if (this.inputMinValue === undefined || newValue === '') {
          this.minError = true;
          return;
        }

        let validatedMinValue = Number(newValue);

        // ввели меньше чем минимум — коррекция на минимально возможное значение
        if (newValue <= this.min) {
          validatedMinValue = this.min;
        }
        // ввели больше чем максимум — схлопываем до текущего верхнего значения
        else if (this.inputMaxValue && newValue > this.inputMaxValue) {
          validatedMinValue = this.inputMaxValue;
        }

        logger.log('syncMin', newValue, 'validated', validatedMinValue);
        this.inputMinValue = validatedMinValue;
        this.newMinValue = undefined;
        this.$nextTick(() => this.emitValue());
      },

      fixMaxValue() {
        logger.log('fixMaxValue', this.newMaxValue);
        this.maxError = false;

        if (this.newMaxValue === undefined) return;

        let newValue = this.newMaxValue;
        newValue = `${newValue}`;
        newValue = newValue.replace(/[^\d]/g, '').trim();

        if (this.inputMaxValue === undefined || newValue === '') {
          this.maxError = true;
          return;
        }

        let validatedMaxValue = Number(newValue);

        // ввели больше чем макс. допустимое — коррекция на макс. значение
        if (newValue >= this.max) {
          validatedMaxValue = this.max;
        }
        // ввели меньше чем минимум — схлопываем до текущего нижнего значения
        else if (this.inputMinValue && newValue < this.inputMinValue) {
          validatedMaxValue = this.inputMinValue;
        }

        logger.log('syncMax', newValue, 'validated', validatedMaxValue);
        this.newMaxValue = undefined;
        this.inputMaxValue = validatedMaxValue;
        this.$nextTick(() => this.emitValue());
      },

      emitValue() {
        this.$emit('input', { min: this.inputMinValue, max: this.inputMaxValue });
      },

      setValue(val) {
        logger.log('setValue', val);
        if (!val) {
          this.reset();
          return;
        }

        this.minError = false;
        this.maxError = false;
        this.inputMinValue = val.min;
        this.inputMaxValue = val.max;
      },

      reset() {
        this.minError = false;
        this.maxError = false;
        this.inputMinValue = this.min;
        this.inputMaxValue = this.max;
        logger.log('reset', `min: ${this.inputMaxValue}, max: ${this.inputMaxValue}`);
      },
    },
  };
</script>

<style lang="scss">
  .range-slider {
    display: flex;
    flex-direction: column;

    .range-slider__slider {
      order: 0;
      margin-top: 0;
    }

    .range-slider__inputs {
      order: 1;
      margin-top: 12px;
    }

    &--slider-on-bottom {
      .range-slider__slider {
        order: 1;
        margin-top: 16px;
      }
      .range-slider__inputs {
        order: 0;
        margin-top: 0;
      }
    }

    .range-slider__inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 14px;

      &:last-child {
        margin-top: 0;
      }
    }

    .range-slider__tooltip {
      &--min {
        text-align: left;
      }

      &--max {
        text-align: right;
      }

      &--text {
        color: c('gray70', 'light');
        font-size: 12px;
      }
    }

    .range-slider__divider {
      position: relative;
      width: 10px;
      height: 18px;
      margin: 0 14px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 0;
        border-bottom: 1px solid c('gray80', 'light');
      }
    }

    .range-slider__input {
      margin-right: 0;
      flex-basis: 50%;
      @include text-style('caption-normal', 'light');

      input {
        color: c('gray80', 'light') !important;
      }

      .v-input__prepend-inner {
        height: 32px;
        margin-top: 0;
        margin-right: 4px;
        line-height: 32px;
        color: c('gray80', 'light') !important;
      }

      .v-input__append-inner {
        height: 32px;
        margin-top: 0;
        margin-left: 4px;
        line-height: 32px;
        color: c('gray80', 'light') !important;
      }
    }
  }
</style>
