<template lang="pug">
edit-filter-base-dialog.sellers-dialog(
  v-bind="$attrs"
  title="Продавец"
  @click:close="clickClose"
  @click:confirm="clickConfirm"
  @click:reset="clickReset"
)
  template(#toolbar)
    .px-4
      v-tabs(:value="tab")
        v-tab(@click="filterName = 'sellers'") По наименованию
        v-tab(@click="filterName = 'seller_type'") По типу

      template(v-if="filterName === 'sellers'")
        ui-search-text-field.mt-3(
          ref="query_input"
          v-model="query"
          placeholder="Наименование или ФИО"
          :clearable="!!query"
          @clear="query = undefined"
          :loading="loading"
        )

        .sellers-dialog-values.mt-3(v-if="sellers && sellers.length")
          ui-chip.sellers-dialog-values__item(
            v-for="(item, index) in sellers"
            :key="'location-address' + index"
            color="primary"
            active=false
            close
            dark
            @click:close="removeSeller(item)"
          ) {{ item }}

  template(#default)
    .seller-filter__content
      template(v-if="filterName === 'sellers'")
        template(v-if="!loading")
          v-list.sellers-dialog-list(v-if="suggestions.length > 0")
            v-list-item.sellers-dialog-item(
              v-for="(item, index) in suggestions"
              :key="index"
              one-line
              @click="addSeller(item)"
            )
              v-list-item-content
                v-list-item-title.sellers-dialog-item__title {{ item }}
                //- v-list-item-subtitle.sellers-dialog-item__desc {{ location.desc }}

        info-panel.mx-4.mt-6(v-if="!!query && !loading && suggestions && suggestions.length === 0")
          | По вашему запросу не найдено совпадений

      template(v-if="filterName === 'seller_type'")
        search-filter-checkbox.px-4(
          :value="sellerType"
          :filter="sellerTypeFilter"
          :hide-title="true"
          @input="setSellerType($event)"
        )
</template>

<script>
  import { UiLoader, UiIcon, UiChip, UiSearchTextField } from '@/uikit';
  import EditFilterBaseDialog from '@/components/search/EditFilterBaseDialog.vue';
  import InfoPanel from '@/components/search/InfoPanel.vue';
  import SearchFilterCheckbox from '@/components/search/SearchFilterCheckbox.vue';
  import debounce from 'underscore/modules/debounce';
  import { SimpleApi as API } from '@/libs/api/lib/simpleApi';
  import { handleError } from '@/libs/handle-error';
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('SearchCategoryDropdown', 'orange');

  export default {
    components: {
      EditFilterBaseDialog,
      InfoPanel,
      SearchFilterCheckbox,
      UiChip,
      UiIcon,
      UiLoader,
      UiSearchTextField,
    },
    inheritAttrs: false,

    props: {
      value: { type: Array, default: () => [] },

      sellersFilter: { type: Object, default: undefined },
      sellersValue: { type: Array, default: () => [] },
      sellerTypeFilter: { type: Object, default: undefined },
      sellerTypeValue: { type: Array, default: undefined },
    },

    data() {
      return {
        query: null,
        loading: false,
        suggestionsOpen: true,
        suggestions: this.sellersFilter?.options?.hints,

        filterName: undefined,
        sellers: this.sellersValue,
        sellerType: this.sellerTypeValue,
      };
    },

    computed: {
      tab() {
        return this.filterName === 'sellers' ? 0 : 1;
      },

      showSuggestions() {
        return Boolean(this.query);
      },

      showHints() {
        return !this.showSuggestions && !this.sellers.length;
      },
    },

    watch: {
      query(value) {
        if (value) {
          this.debouncedSearch();
        } else {
          this.initSuggestions();
        }
      },

      sellers(newValue) {
        if ((!newValue || newValue.length == 0) && !this.query) {
          this.initSuggestions();
        }
      },
    },

    created() {
      this.debouncedSearch = debounce(this.fetchSuggestions, 400);
    },

    mounted() {
      this.initialize();
    },

    methods: {
      initialize() {
        this.filterName = this.sellerTypeValue !== undefined ? 'seller_type' : 'sellers';
        if (this.filterName === 'sellers') {
          this.initSuggestions();
        }
      },

      addSeller(item) {
        const sellers = this.sellers || [];
        if (sellers?.includes(item)) return;
        this.sellers = [...sellers, item];

        // скидываем, ибо теперь ставим продавцов
        this.sellerType = undefined;
      },

      removeSeller(item) {
        this.sellers = this.sellers.filter((v) => v != item);

        // скидываем, ибо теперь ставим продавцов
        this.sellerType = undefined;
      },

      setSellerType(value) {
        this.sellerType = value;

        // скидываем, ибо теперь ставим тип продавца
        this.sellers = undefined;
      },

      async fetchSuggestions() {
        this.loading = true;
        try {
          const response = await API.get(this.sellersFilter.options.api, {
            search: { query: this.query },
          });
          if (response && response.data) {
            this.suggestions = response.data?.data?.items;
          }
        } catch (error) {
          handleError(error, logger);
          this.loading = false;
        } finally {
          this.loading = false;
        }
      },

      initSuggestions() {
        this.suggestions = this.sellersFilter?.options?.hints;
      },

      clickClose() {
        this.$emit('click:close');
      },

      clickConfirm() {
        this.$emit('update:sellersValue', this.sellers);
        this.$emit('update:sellerTypeValue', this.sellerType);
        this.$emit('click:confirm');
      },

      clickReset() {
        this.sellers = undefined;
        this.sellerType = undefined;
        this.$emit('update:sellersValue', this.sellers);
        this.$emit('update:sellerTypeValue', this.sellerType);
        this.$emit('click:reset');
      },
    },
  };
</script>

<style lang="scss">
  .sellers-dialog {
    .sellers-dialog-list {
    }

    .sellers-dialog-values {
      display: flex;
      flex-wrap: wrap;
    }

    .sellers-dialog-values__item {
      margin-right: 8px;
      margin-bottom: 8px;
    }

    .sellers-dialog-item {
      width: 100%;
      max-width: 100%;

      .sellers-dialog-item__title {
        @include text-style('caption-normal', 'light');
        color: c('gray80', 'light');
      }

      .sellers-dialog-item__desc {
        @include text-style('caption-small', 'light');
        margin-top: 2px;
        color: c('gray60', 'light');
      }
    }
  }
</style>
