<template lang="pug">
.search-filter-checkbox
  slot(v-if="!hideTitle" name="title" :title="filter.label")
    h6.text--h6.mb-2 {{ filter.label }}

  v-list.search-filter-checkbox-list
    v-checkbox.search-filter-checkbox-list__item(
      v-for="option in options"
      :key="'filter-checkbox-' + filter.name + '-' + option.key"
      v-model="values"
      :value="option.key"
      hide-details
      @change="valuesChanged"
    )
      template(#label)
        .t-caption-normal {{ option.label | capitalize }}
</template>

<script>
  export default {
    name: 'search-filter-checkbox',
    props: {
      filter: { type: Object, required: true },
      value: { type: Array, default: () => [] },
      hideTitle: { type: Boolean, default: false },
      small: { type: Boolean, default: false },
      noWrap: { type: Boolean, default: false },
      noEmptyOption: { type: Boolean, default: false },
    },

    data() {
      return {
        values: this.value,
      };
    },

    computed: {
      options() {
        if (!this.noEmptyOption) return this.filter.options;
        const options = this.filter.options.filter((opt) => opt.key !== '' && opt.key !== 'any');
        return options;
      },
    },

    watch: {
      value(newValue) {
        this.values = newValue;
      },
    },

    methods: {
      valuesChanged(values) {
        // последнее добавленное — клик на "Неважно" (значение 'any')
        if (values[values.length - 1] === 'any') {
          // обнуляем все, кроме "Неважно"
          this.values = ['any'];
        } else {
          this.values = this.values.filter((v) => v !== 'any');
        }
        this.$emit('input', this.values);
      },
    },
  };
</script>

<style lang="scss">
  .search-filter-checkbox-list {
    margin: 0;
    padding: 0 !important;

    .search-filter-checkbox-list__item {
      margin: 0;
      padding: 9px 0;
      border-bottom: 1px solid c('gray10', 'light');
      color: c('gray100', 'light') !important;
    }
  }
</style>
