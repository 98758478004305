<template lang="pug">
edit-filter-base-dialog.category-dialog(
  v-bind="$attrs"
  title="Категория"
  @click:close="clickClose"
  @click:confirm="clickConfirm"
  @click:reset="clickReset"
)
  template(#toolbar)
    .px-4
      ui-search-text-field.mt-3.mb-2(
        ref="query_input"
        v-model="query"
        placeholder="Найти по названию"
        :clearable="!!query"
        @clear="query = undefined"
        @keyup.esc.native.prevent.stop="query = undefined"
        :loading="loading"
      )

  template(#default)
    .category-dialog__content
      // поиск по запросу
      template(v-if="!!query")
        v-radio-group.category-dialog-list.mx-4(
          v-if="foundCategories.length > 0"
          :value="selection ? selection.kind : undefined"
        )
          v-radio.category-dialog-list__item(
            v-for="(item, index) in foundCategories"
            :key="`category-${item.kind}-${index}`"
            :value="item.kind"
            :ripple="false"
            @change="setValue(item)"
          )
            template(#label)
              .category-dialog-item
                .category-dialog-item__title {{ item.title }}
                .category-dialog-item__count {{ item.total }}

        info-panel.mx-4.mt-6(v-if="!loading && foundCategories.length === 0")
          | По вашему запросу не найдено совпадений

      // иначе дерево
      template(v-else)
        v-radio-group.category-dialog-list.mx-4(
          :key="`categories-list-${opened.join('-')}`"
          :value="selection"
          mandatory
        )
          v-radio.category-dialog-list__item(:value="undefined" @change="setValue(undefined)")
            template(#label)
              .category-dialog-item
                .category-dialog-item__title Все активы
                .category-dialog-item__count {{ totalAssetCount }}
          v-radio.category-dialog-list__item.mr-8(
            :class="`-lev${item.level}`"
            v-for="(item, index) in categories"
            :key="`category-${item.kind}-${index}`"
            :value="item"
            :ripple="false"
            @change="setValue(item)"
          )
            template(#label)
              .category-dialog-item
                .category-dialog-item__title {{ item.title }}
                .category-dialog-item__count {{ item.total }}
</template>

<script>
  import { UiLoader, UiIcon, UiSearchTextField } from '@/uikit';
  import EditFilterBaseDialog from '@/components/search/EditFilterBaseDialog.vue';
  import InfoPanel from '@/components/search/InfoPanel.vue';
  import { service as API } from '@/services/assets';
  import debounce from 'underscore/modules/debounce';
  import uniq from 'underscore/modules/uniq';

  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('SearchCategoryDropdown', 'orange');

  export default {
    components: { EditFilterBaseDialog, InfoPanel, UiLoader, UiIcon, UiSearchTextField },
    inheritAttrs: false,

    props: {
      categoriesTree: { type: Object, required: true },
      loading: { type: Boolean, default: false },
      skipEmpty: { type: Boolean, default: false },
      category: { type: Object, default: undefined },
      kind: { type: String, default: undefined },
    },

    data() {
      return {
        selection: this.category,
        categories: [],
        foundCategories: [],
        query: '',
        opened: [],
        totalAssetCount: undefined,
      };
    },

    watch: {
      category(value) {
        this.selection = value;
      },

      selection(newCategory, oldCategory) {
        logger.log('👀 selection:', `${oldCategory} -> ${newCategory}`, `opened: ${this.opened}`);
        this.expandBranches(newCategory, oldCategory);
        this.updateCategories();
      },

      query(value) {
        if (value) {
          this.debouncedSearch();
        }
      },
    },

    created() {
      this.debouncedSearch = debounce(this.searchCategories, 400);
    },

    mounted() {
      this.initialize();
    },

    methods: {
      initialize() {
        this.expandBranches(this.category);
        logger.log('Инициализация', `${this.category}`, `ветки: [${this.opened}]`);
        this.updateCategories();
        this.getTotalAssetCount();
      },

      searchCategories() {
        if (!this.query) {
          this.foundCategories = this.categoriesTree.getIndex();
          return;
        }

        try {
          logger.log('Поиск по запросу', this.query);
          this.foundCategories = this.categoriesTree.getIndex().reduce((found, c) => {
            if (c.total > 0 && c.title.toLowerCase().indexOf(this.query.toLowerCase()) !== -1)
              found.push(c);
            return found;
          }, []);
        } catch (error) {
          logger.error(error);
        }
      },

      async getTotalAssetCount() {
        try {
          this.totalAssetCount = await API.getAssetsCount(null, 'buy');
        } catch (error) {
          logger.log(error);
        }
      },

      updateCategories() {
        try {
          logger.log('Обновление дерева категорий', this.categoriesTree, this.opened);
          this.categories = this.categoriesTree.getIndex().reduce((found, c) => {
            const topLevel = c.level === 1;
            const subkind =
              this.opened.includes(c.parents[0]) || this.opened.includes(c.parents[1]);
            if (c.total > 0 && (topLevel || subkind)) found.push(c);
            return found;
          }, []);
        } catch (error) {
          logger.error(error);
        }
      },

      clickClose() {
        this.$emit('click:close');
      },

      clickConfirm() {
        this.$emit('update:category', this.selection);
        this.$emit('update:kind', this.selection ? this.selection.kind : undefined);
        this.$emit('confirm');
      },

      clickReset() {
        this.selection = undefined;
        this.$emit('update:category', undefined);
        this.$emit('update:kind', undefined);
        this.$emit('reset');
      },

      setValue(category) {
        logger.log('Устанавливаем значение', `${category}`);
        this.selection = category;
      },

      expandBranches(newCategory) {
        let opened = [...this.opened];
        if (newCategory) {
          opened = [].concat(opened, newCategory.parents);
          if (!newCategory.leaf) opened = [].concat(opened, newCategory.kind);
          this.opened = uniq(opened);
          logger.log(`Разворачиваем ветки...[${this.opened}]`);
        }
      },
    },
  };
</script>

<style lang="scss">
  .category-dialog {
    .v-radio.category-dialog-list__item {
      align-items: unset;
      border-bottom: 1px solid rgba(232, 233, 237, 1);
      padding-bottom: 12px !important;
      padding-top: 12px !important;
      margin-bottom: 0 !important;

      &:first-child{
        padding-top: 0 !important;
      }

      &.-lev2 {
        margin-left: 32px;
      }

      &.-lev3 {
        margin-left: 64px;
      }

      &.v-item--active {
        .category-dialog-item__title {
          font-weight: 500;
          color: c('gray100', 'light');
        }
      }
    }

    .category-dialog-item {
      width: 100%;
      max-width: 100%;
      display: inline;

      .category-dialog-item__title {
        @include text--normal;
        display: inline;
        flex-shrink: 1;
        min-width: 0;
      }

      .category-dialog-item__count {
        display: inline;
        @include font-size(12, 16);
        color: c('gray60', 'light');
        flex-shrink: 1;
        margin-left: 6px;
      }
    }
  }
</style>
