<template lang="pug">
.search-filter-inline-radio-new
  slot(v-if="!hideTitle" name="title" :title="filter.label")
    h6.text--h6.mb-3 {{ filter.label }}

  ui-btn-toggle(v-model="filterValue" mandatory)
    ui-btn(
      v-for="(option, index) in options"
      :key="filter.name + '-' + option.key + index"
      :value="option.key"
    ) {{ option.label | capitalize }}
</template>

<script>
  import { UiBtn, UiBtnToggle } from '@/uikit';
  import { themeable } from '@/uikit/mixins/themeable';

  export default {
    name: 'search-filter-inline-radio-new',
    components: { UiBtn, UiBtnToggle },
    mixins: [themeable],

    props: {
      filter: { type: Object, required: true },
      hideTitle: { type: Boolean, default: false },
      small: { type: Boolean, default: false },
      value: { type: String, default: 'any' },
      noEmptyOption: { type: Boolean, default: false },
      mandatory: { type: Boolean, default: false },
    },

    computed: {
      options() {
        if (!this.noEmptyOption) return this.filter.options;
        const options = this.filter.options.filter((opt) => opt.key !== '' && opt.key !== 'any');
        return options;
      },

      filterValue: {
        set(value) {
          this.$emit('input', value);
        },
        get() {
          return this.value;
        },
      },
    },
  };
</script>
