<template lang="pug">
.edit-filter-base-dialog(:class="classes" :style="measurableStyles")
  section.edit-filter-base-dialog__header
    .edit-filter-base-dialog__toolbar
      .edit-filter-base-dialog__toolbar-reset(v-if="resetable" @click="clickReset") Сбросить
      .edit-filter-base-dialog__toolbar-title(v-if="title") {{ title }}
      .edit-filter-base-dialog__toolbar-close(v-if="closable" @click="clickClose") Отмена

    .edit-filter-base-dialog__toolbar-slot(v-if="$slots.toolbar")
      slot(name="toolbar")

  section.edit-filter-base-dialog__content
    slot(:on="{ input: updateReturnValue }")

  footer.edit-filter-base-dialog__footer(v-if="!fromSearchDialog")
    ui-btn(
      v-if="loading"
      large
      primary
      width="100%"
    )
      ui-loader(small inline)
    ui-btn(
      v-else
      large
      primary
      width="100%"
      @click="clickConfirm"
      :disabled="disabled"
    ) {{ confirmText }}
</template>

<script>
  import { UiBtn, UiIcon, UiLoader } from '@/uikit';
  import EventListener from '@/uikit/components/functional/EventListener.vue';
  import { measurable } from '@/uikit/mixins/measurable.js';

  export default {
    name: 'edit-filter-base-dialog',
    components: { UiBtn, UiIcon, UiLoader, EventListener },
    mixins: [measurable],

    props: {
      title: { type: String, default: undefined },
      confirmText: { type: String, default: 'Готово' },
      closable: { type: Boolean, default: true },
      resetable: { type: Boolean, default: true },
      paddless: { type: Boolean, default: true },
      disabled: { type: Boolean, default: false },
      loading: { type: Boolean, default: false },
      fromSearchDialog: { type: Boolean, default: false },
      // initialValue: { type: [Number, String, Object], default: undefined },
    },

    data() {
      return {
        returnValue: undefined,
      };
    },

    computed: {
      classes() {
        return [
          { 'edit-filter-base-dialog--paddless': this.paddless },
          { 'edit-filter-base-dialog--resetable': this.resetable },
        ];
      },
    },

    methods: {
      updateReturnValue(value) {
        this.returnValue = value;
      },

      clickReset() {
        if (this.disabled) return;
        this.$emit('click:reset');
      },

      clickConfirm() {
        if (this.disabled) return;

        // если были @input события в слоте (поменялся returnValue)
        // то подтверждаем и шлем наверх значение
        if (this.returnValue !== undefined) {
          this.$emit('update:returnValue', this.returnValue);
        }

        this.$emit('click:confirm');
      },

      clickClose() {
        if (this.disabled) return;
        this.$emit('click:close');
      },
    },
  };
</script>

<style lang="scss">
  .edit-filter-base-dialog {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: c('white', 'light');
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  .edit-filter-base-dialog__header {
    width: 100%;
  }

  .edit-filter-base-dialog__toolbar {
    position: relative;
    display: flex;
    width: 100%;
    height: 48px;
    align-items: center;
  }

  .edit-filter-base-dialog__toolbar-reset {
    @include text-style('button-middle', 'light');
    position: absolute;
    top: 0px;
    left: 0;
    width: 102px;
    height: 48px;
    padding: 16px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: c('primary60', 'light');
  }

  .edit-filter-base-dialog__toolbar-title {
    @include text-style('h3-mobile', 'light');
    text-align: center;
    flex-grow: 1;
    flex-shrink: 0;
    color: c('gray100', 'light');

    .edit-filter-base-dialog.edit-filter-base-dialog--resetable & {
      padding-left: 102px;
      padding-right: 102px;
      width: 100%;
      min-width: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .edit-filter-base-dialog__toolbar-close {
    @include text-style('button-middle', 'light');
    position: absolute;
    top: 0px;
    right: 0;
    padding-right: 16px;
    height: 48px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: c('gray70', 'light');
  }

  .edit-filter-base-dialog__toolbar-slot {
    width: 100%;
  }

  .edit-filter-base-dialog__content {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
    min-height: 0;
    max-height: 100%;
    height: 100%;
    padding: 16px 16px 24px 16px;
    overflow-x: hidden;

    .edit-filter-base-dialog.edit-filter-base-dialog--paddless & {
      padding: 0;
    }
  }

  .edit-filter-base-dialog__footer {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 12px 16px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.06);
  }
</style>
