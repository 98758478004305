<template lang="pug">
.filter-box.ui-clickable(
  :class="{ '-active': active, '-has-value': hasValue }"
  v-on="$listeners"
  v-bind="$attrs"
)
  template(v-if="loading")
    ui-loader.filter-box__loader(inline size="20")
  template(v-else)
    slot
      | {{ hasValue ? value : emptyValue }}
</template>

<script>
  import { UiLoader } from '@/uikit';

  export default {
    components: { UiLoader },
    inheritAttrs: false,
    props: {
      value: { type: String, default: undefined },
      emptyValue: { type: String, default: 'Неважно' },
      active: { type: Boolean, default: false },
      loading: { type: Boolean, default: false },
    },

    computed: {
      hasValue() {
        return !!this.value;
      },
    },
  };
</script>

<style lang="scss">
  .filter-box {
    // @include text-style('button-middle');
    position: relative;
    padding: 8px 44px 8px 16px;
    background-color: c('gray5', 'light');
    font-size: 15px;
    line-height: 16px;
    height: 32px;
    color: c('gray80', 'light');
    border-radius: 16px;
    width: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      right: 8px;
      top: 4px;
      @include svg-bg-mask-recolored('chevron-down', c('gray80', 'light'));
    }

    .filter-box__loader {
      position: absolute;
      left: 8px;
      top: 4px;
      width: 24px;
      height: 24px;
    }

    &.-sm {
      width: 50%;
      padding: 8px 16px;

      &::after {
        display: none;
      }
    }

    &:not(.-active):hover,
    &:not(.-has-value):hover {
      color: c('white', 'light');
      background-color: c('primary40', 'light');
      box-shadow: shadow-border($color: c('primary40', 'light'), $size: 1px);

      &::after {
        background-color: c('white', 'light');
      }
    }

    &.-has-value {
      background-color: c('white', 'light');
      box-shadow: shadow-border($color: c('gray40', 'light'), $size: 1px);
    }

    &.-active {
      background-color: c('primary10', 'light');
      box-shadow: shadow-border($color: c('primary60', 'light'), $size: 2px);
    }
  }
</style>
